<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md=""
          class="mb-md-0 mb-2"
        >
          <label>Date</label>
          <flat-pickr
            v-model="date"
            class="form-control"
            :config="{ mode: 'range'}"
            @input="(val) => $emit('update:date', val)"
          />
        </b-col>
        <b-button
          class="mt-2"
          variant="primary"
          @click="resetDate()"
        >Clear
        </b-button>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    flatPickr,
    BButton,
  },
  props: {
    date: {
      type: [String, null],
      default: null,
    },
  },
  methods: {
    resetDate() {
      this.data = null
      this.$emit('update:date', null)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
